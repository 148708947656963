<script setup lang="ts">
import { useResizeObserver } from '@vueuse/core';
import { computed, ref, provide, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

withDefaults(defineProps<{
  width?: 'default' | 'narrow'
  header?: 'default' | 'super'
}>(), {
  width: 'default',
  header: 'default',
});

const route = useRoute();
const routesWithCrumbs = computed(() => (route.path.split('/').length > 2 ? route.matched.filter((item) => item.meta.breadcrumb) : []));

const navElm = ref(null);
const headerElm = ref(null);
const headerSpec = reactive({ height: 0, width: 0 });
const navSpec = reactive({ height: 0, width: 0 });

watch([headerElm, navElm], async () => {
  if (navElm.value || headerElm.value) {
    try {
      useResizeObserver(navElm.value, (entries) => {
        const [entry] = entries;
        const { width, height } = entry.contentRect;

        // setTimeout allow the ResizeObserver loop to finish
        // before this effect modifies the observed element.
        // This prevents the error "ResizeObserver loop completed with undelivered notifications"
        setTimeout(() => {
          navSpec.width = width;
          navSpec.height = height;
        }, 0);
      });
      useResizeObserver(headerElm.value, (entries) => {
        const [entry] = entries;
        const { width, height } = entry.contentRect;

        // setTimeout allow the ResizeObserver loop to finish
        // before this effect modifies the observed element.
        // This prevents the error "ResizeObserver loop completed with undelivered notifications"
        setTimeout(() => {
          headerSpec.width = width;
          headerSpec.height = height;
        }, 0);
      });
    } catch (e) {
      /* eslint-disable-next-line */
      console.log('error: ', e);
      navSpec.width = 0;
      navSpec.height = 0;
      headerSpec.width = 0;
      headerSpec.height = 0;
    }
  }
});

provide('headerSpec', headerSpec);
provide('navSpec', navSpec);

</script>

<template>
  <div :class="['pando__page', `m-${width}`]">
    <div

      class="flex gap-md"
    >
      <div
        v-if="$slots['preheader']"
        class="e-preheader"
      >
        <slot name="preheader" />
      </div>
      <div class="w-1">
        <nav
          ref="navElm"
          aria-label="breadcrumbs"
          class="e-breadcrumbs"
        >
          <ul class="e-breadcrumb-list">
            <template v-for="(item, index) in routesWithCrumbs">
              <li
                v-if="item.meta.breadcrumb"
                :key="item.name"
                class="e-breadcrumb-list-item"
              >
                <!-- add caret when more than 1 item -->
                <span
                  v-if="index > 0"
                  aria-hidden="true"
                  class="e-breadcrumb-caret"
                >></span>
                <RouterLink
                  v-if="index < (routesWithCrumbs.length - 1)"
                  class="e-breadcrumb-link"
                  :to="route.query.uuid ? `${item.path}?uuid=${route.query.uuid}` : item.path"
                >
                  {{ item.meta.breadcrumb }}
                </RouterLink>
                <span
                  v-else
                  class="e-breadcrumb-text"
                >{{ item.meta.breadcrumb }}</span>
              </li>
            </template>
          </ul>
        </nav>
        <div
          ref="headerElm"
          :class="['e-page-header', `m-${header}`]"
        >
          <slot name="header" />
        </div>
      </div>
    </div>
    <div class="e-content">
      <!-- leave as default -->
      <slot />
    </div>
    <div class="e-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="postcss">
.pando__page {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--layout-unit-large) var(--layout-unit-medium) 0 0;
  @media only screen and (max-width: 680px) {
    padding: 0;
  }
  & .e-page-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    padding-bottom: var(--layout-unit-medium);
    &.m-super {
      flex-direction: column;
      width: 100%;
      text-align: center;
      & h1 {
        line-height: 1;
        margin-bottom: var(--layout-unit-small);
      }
    }
    & .e-header-ctas {
      display: inline-block;
      display: flex;
      width: fit-content;
    }
  }
  & .e-breadcrumbs {
    width: 100%;
    padding: 0;
    & .e-breadcrumb-list {
      margin: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      font-weight: var(--font-weight-medium);
      color: inherit;
      & span.e-breadcrumb-text {
        color: inherit;
      }
      & .e-breadcrumb-caret {
        padding: 0 var(--layout-unit-extra-small);
        font-size: var(--font-size-body);
        font-weight: var(--font-weight-medium);
        color: inherit;
      }
      & .e-breadcrumb-link {
        font-weight: var(--font-weight-medium);
        text-decoration: none;
        color: inherit;
        &:hover {
          color: var(--green-primary);
          text-decoration: none;
        }
      }
    }
  }
  & > .e-content {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
  }
  & .e-footer {
    z-index: 2;
    position: sticky;
    bottom: 0;
    top: 0;
    width: 100%;
  }
  &.m-narrow {
    width: 800px;
    max-width: 100%;
    margin: auto;
  }
  @media print {
    width: 100%;
    padding: 0 !important;
    height: 100%;
    & .e-footer,
    & .e-breadcrumbs {
      display: none !important;
    }
    &.m-default,
    &.m-narrow {
        width: 100%;
      max-width: unset;
    }
    & > .e-content {
      width: 100% !important;
      display: flex;;
    }
  }
}
</style>
